import React from 'react';
import { BrowserRouter as ReactRouter, Route, Switch } from 'react-router-dom';
import Header from './components/Header';
import Main from './components/Main';
import Wallet from './components/Wallet';
import Manu from './components/Manu';

const Router = () => {
  return (
    <ReactRouter>
      <Header />
      <Switch>
        <Route
          exact
          path='/'
          render={() => (
            <>
              <Main />
            </>
          )}
        />
        <Route exact path='/wallet' render={() => <Wallet />} />
        <Route exact path='/manu' render = {() => <Manu />} />
      </Switch>
    </ReactRouter>
  );
};

export default Router;
