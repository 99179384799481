import Web3 from "web3";
import './multisig.css';
import { buzzToken_ABI, lp_ABI } from '../utils/buzzAbis';
import { useState} from 'react'; //, useEffect 
import { ContractAddesses } from '../utils/addresses';


const win = window as any
const docu = document as any
win.bsc = new Web3('https://bsc-dataseed1.binance.org:443');



const Wallet = () => {
    const [buzzBalance, setBuzzBalance] = useState(0);
    const [buzzLiquidityV1BNB, setBUZZLiquidityV1BNB] = useState(0);
    const [buzzLiquidityV2BNB, setBUZZLiquidityV2BNB] = useState(0);
    const [buzzLiquidityV1Buzz, setBUZZLiquidityV1Buzz] = useState(0);
    const [buzzLiquidityV2Buzz, setBUZZLiquidityV2Buzz] = useState(0);

  
    // useEffect(() => {
    //     async function getInfos() {
    //       await getBscBalance();
    //       await getAvaxBalance(); 
    //     }
    //     getInfos()
    //   }, [])

const getBalances = async () => {
    console.log("value wallet")
    console.log(docu.getElementById("_wallet_address").value);
    
          await getBuzzBalance();
          await getBUZZLiquidityV1();
          await getBUZZLiquidityV2();
      };
    

const getBuzzBalance = async () => {

    const bscSpore = new win.bsc.eth.Contract(
        buzzToken_ABI,
      ContractAddesses.BUZZ_TOKEN
    );

    const wallet_address = docu.getElementById("_wallet_address").value;
    const _buzzBalance = await bscSpore.methods.balanceOf(wallet_address).call();
    console.log("balance buzz")
    console.log(_buzzBalance)
    setBuzzBalance(_buzzBalance);

}

const getBUZZLiquidityV1 = async () => {
        const LP = new win.bsc.eth.Contract(
        lp_ABI,
      ContractAddesses.BUZZ_LP_V1
    );

    const wallet_address = docu.getElementById("_wallet_address").value;
    const _liquidityBalance =  await LP.methods.balanceOf(wallet_address).call();
    const reserves=  await LP.methods.getReserves().call();
    const _totalLiquidity=  await LP.methods.totalSupply().call();

    const _liquidityBNBBalance= reserves._reserve1 * _liquidityBalance /_totalLiquidity 
    const _liquidityBuzzBalance = reserves._reserve0 *  _liquidityBalance /_totalLiquidity 

    setBUZZLiquidityV1BNB(_liquidityBNBBalance);
    setBUZZLiquidityV1Buzz(_liquidityBuzzBalance);


}

const getBUZZLiquidityV2 = async () => {
    const LP = new win.bsc.eth.Contract(
    lp_ABI,
  ContractAddesses.BUZZ_LP_V2
);

const wallet_address = docu.getElementById("_wallet_address").value;
const _liquidityBalance =  await LP.methods.balanceOf(wallet_address).call();
const reserves=  await LP.methods.getReserves().call();
const _totalLiquidity=  await LP.methods.totalSupply().call();

const _liquidityBNBBalance= reserves._reserve1 * _liquidityBalance /_totalLiquidity 
const _liquidityBuzzBalance = reserves._reserve0 *  _liquidityBalance /_totalLiquidity 

setBUZZLiquidityV2BNB(_liquidityBNBBalance);
setBUZZLiquidityV2Buzz(_liquidityBuzzBalance);


}



// const getBscLiquidityBalance = async () => {

//     const bscLiquidityPool = new win.bsc.eth.Contract(
//         BSC_LIQUIDITY_ABI,
//       ContractAddesses.BSC_SPORE_BNB_PANCAKE_PAIR
//     );

//     const bscMasterChefOlive = new win.bsc.eth.Contract(
//         BSC_OLIVE_MASTERCHEF_ABI,
//       ContractAddesses.BSC_OLIVE_MASTERCHEF
//     );

//     const wallet_address = docu.getElementById("_wallet_address").value;
//     const _bscLiquidityBalance =  await bscLiquidityPool.methods.balanceOf(wallet_address).call();
//     const _bscLiquidityReserves=  await bscLiquidityPool.methods.getReserves().call();
//     const _bscTotalLiquidity=  await bscLiquidityPool.methods.totalSupply().call();
    
//     const _bscLiquidityBNBBalance= _bscLiquidityReserves._reserve1 * _bscLiquidityBalance /_bscTotalLiquidity 
//     const _bscLiquiditySPOREBalance = _bscLiquidityReserves._reserve0 * _bscLiquidityBalance  / _bscTotalLiquidity

//     const _bscOliveBalance = await bscMasterChefOlive.methods.userInfo(12,wallet_address).call();
//     const _bscOliveBNBBalance= _bscLiquidityReserves._reserve1 * _bscOliveBalance.amount/ _bscTotalLiquidity
//     const _bscOliveSPOREBalance = _bscLiquidityReserves._reserve0 * _bscOliveBalance.amount/_bscTotalLiquidity

//     const bscPendingOlive = await bscMasterChefOlive.methods.pendingOlive(12,wallet_address).call();

//     setBscLiquidityBNB(_bscOliveBNBBalance+_bscLiquidityBNBBalance)
//     setBscLiquiditySPORE(_bscLiquiditySPOREBalance+_bscOliveSPOREBalance)
//     setBscPendingOlive(bscPendingOlive);

// }


return (
    <>
           <div> Your wallet Address:
                <input
                    type="text"
                    id="_wallet_address"
                    
                  />
            </div>

      <span className='price'>
        <button
          className='btn btn-primary'
          aria-label='Balance'
          onClick={getBalances}
          > Fetch Balances
        </button>
      </span>
      <div>
          <h3>Raw Balance</h3>
        BSC Balance:{buzzBalance/10**9}
        <br></br>

      </div>     
      <div>
          <h3>Liquidity Balance</h3>
          <h4>V1</h4>
          BNB: {buzzLiquidityV1BNB/10**18}
          <br></br>
          Buzz: {buzzLiquidityV1Buzz/10**18}
            <br></br>
            <h4>V2</h4>
            BNB: {buzzLiquidityV2BNB/10**18}
            <br></br>
            Buzz: {buzzLiquidityV2Buzz/10**18}

      </div>
    </>
  );
}

export default Wallet;