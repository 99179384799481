export enum ContractAddesses {
    BUZZ_TOKEN= "0x2c41de2e96aa5ef5807e2d140db2ec23316d190d",
    BUZZ_LP_V1= "0x63a3f01f67e22808f717a9b014e4cab7dd622027",
    BUZZ_LP_V2= "0x5444a5212a2fa11e3042aee06d58c2cac323e8b9",
    AVAX_SPORE_MAINNET = "0x6e7f5C0b9f4432716bDd0a77a3601291b9D9e985",
    BSC_SPORE_MAINNET ="0x33a3d962955a3862c8093d1273344719f03ca17c",
    BSC_SPORE_BNB_PANCAKE_PAIR ="0x4aA8F0ef7dd950e260d5EeaF50A1D796D0cefd2f",
    BSC_OLIVE_MASTERCHEF="0xD92bc4Afc7775FF052Cdac90352c39Cb6a455900",
    // AVAX_SPORE_FUJI = "0x75e6fb313df2c9429457722e4adf89e2a9b39cff",
    AVAX_SPORE_FUJI = "0x53D88eF1e79b26E59f1e1852134B619078EF309C",
    AVAX_TESTNET_ROUTER= "0x2D99ABD9008Dc933ff5c0CD271B88309593aB921",
    AVAX_MULTISIG_TESTNET= "0x4578Cf247B135e2bE7cE0B148342c355614b3131",
    BSC_LAVA_MASTERCHEF= "0xfbfae2D489Bb649C7f33d9812b2Dcf17E9bb279C",
    BSC_LAVA_TIMELOCK="0xd74d11B1f4D0E2e1C71210B25456670A19B09b34",
    LiquidityPoolManagerV2= "0x912b5D41656048Ef681eFa9D32488a3fFE397994",
    PNG_SPORE_PNGPAIR= "0xad24a72ffE0466399e6F69b9332022a71408f10b",
    AVAX_SPORE_STAKING_REWARDS="0x12A33F6B0dd0D35279D402aB61587fE7eB23f7b0",
    PNG_SPORE_STAKING_REWARDS="0x12A33F6B0dd0D35279D402aB61587fE7eB23f7b0",
    AVAX_TIMELOCK ="0xB4cf6f420FEf0CeFc0Bbe8bD16E881e8fAAA2909",
    LAVAX_MASTERCHEF="0x967f83C62c67e35ed501bF045FeB188D6a9035Fb",
    BAG_SHIV_WAVAX="0xD4150762dd6c3621aFDeE887aaB091df269db11a",
    PNG_SHIV_WAVAX="0x82Ab53e405fa94448597aFCC0BA86143b1AB2628",
    BAG_SHIV_BAG="0x1fBd931AE22a7ab0E16aE57edafE00320fE94692",
    LYDIA_ANKR_WAVAX="0xbA4486E7A6f74be11Fb7159D205F876168C906AA",
    PNG_ANKR_WAVAX="0xAa9A58792CBFA3DE9Cef36a5CF0E3608a6a106B7",
    SHIVAVAX= "0x440aBbf18c54b2782A4917b80a1746d3A2c2Cce1",
    GBWAVAX= "0x0A1041fEB651b1daa2f23EBa7DAB3898D6b9A4Fe",
    GB= "0x90842eb834cFD2A1DB0b1512B254a18E4D396215",
    SPORE_WAVAX="0x0a63179a8838b5729E79D239940d7e29e40A0116",
    OLIVE_SPORE_AVAX="0x0FA496C08D2b6F449cD6a2d72cea76b3EA8C703a",
    ANKR="0x6C6f910A79639dcC94b4feEF59Ff507c2E843929",
    tortugaJOELP= "0xfa82fe4b0da3178863c9334c110bd3796ceadda8",
    BAGWAVAXLP="0x73a5bc09387798ae890f7f2c084f38840d06d5a5",
    TANGLWAVAX="0x7870B42206ed0bC0c53BdDeDCf684c96F70327c1",
    PNG_WAVAX_LP="0xd7538cabbf8605bde1f4901b47b8d42c61de0367"
}

export enum NetWorkIds {
    BSC_TESTNET = "97",
    BSC_MAINNET = "56",
    AVAX_MAINNET = "43114",
    AVAX_FUJI = "43113"
} 