import React from 'react';
import './Main.css';


const Main = () => {
    return (
      <>
      <section>

      <div className="container  py-3">
      <br></br>
      Have you secured your bag of buzz?
      </div>

      <div>
     <h2> MANU token + farm launch: ASAP</h2>
      </div>
      <div>
      <img src='/img/news.jpeg' alt="reload your page" height="500"/>
      </div>
      <div>
        ....
      upcoming NFT collection...
      </div>
      </section>
      
      <section>
            <div className="container  py-5">
              <div className="row py-5">
                <div className="row">

                <div className="column">
                  <img src='/img/lgm-31.png' alt="reload your page" height="200"/>
                  <img src='/img/lgm-33.png' alt="reload your page" height="200" />
                  <img src='/img/lgm-40.png' alt="reload your page" height="200"/>
                  <img src='/img/lgm-50.png' alt="reload your page" height="200"/>
                </div>

                <div className="column">
                  <img src='/img/lgm-66.png'  alt="reload your page" height="200"/>
                  <img src='/img/lgm-72.png' alt="reload your page" height="200"/>
                  <img src='/img/lgm-95.png' alt="reload your page" height="200"/>
                  <img src='/img/lgm-96.png' alt="reload your page" height="200"/>
                </div>
                <div className="column">

                  
                 Mistical dances
                  
                </div>

                <div className="column">

                  
                  <img src='/img/tenor.gif' alt="reload your page" height="200"/>
                  
                </div>



                </div>
              </div>
            </div>
      </section>
     


      </>
    )
  }
  
export default Main