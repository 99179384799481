// src/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import './header.css';


const Header = () => {
  return (
    <header className='header'>
      <nav className='navbar navbar-expand-lg py-3'>
        <div className='container-fluid'>
          <a href='/' className='navbar-brand'>
            <img src='lgm-logo.png' className='logo' alt='LGM-MAFIA' />{' '}
            <h1 className='font-weight-bold d-inline'>LGM-MAFIA</h1>
          </a>
        <button
            type='button'
            data-toggle='collapse'
            data-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
            className='navbar-toggler navbar-toggler-right'>
            <i className='fa fa-bars'></i>
        </button>
        <div id='navbarSupportedContent' className='collapse navbar-collapse'>
        <li className='nav-item'>
                <Link to='/manu' style={{ textDecoration: 'none' }}>
                  <span className='nav-link font-weight-bold' title='Manu farm'> Enter Manu </span>
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/wallet' style={{ textDecoration: 'none' }}>
                  <span className='nav-link font-weight-bold' title='Wallet'> Track your tokens </span>
                </Link>
              </li>
          </div>

        </div>
      </nav>
    </header>
  )
}

export default Header
